import React, { Component } from 'react';
import { Row, Col } from 'antd';
import 'antd/dist/antd.css';
import Markdown from 'react-markdown';
import moment from 'moment';

class PostItem extends Component {

  render() {
    const post = this.props.post;
    // console.log('PostItem Component', post);

    return (
      <Row>
        <Col key={post.id} sm={{ span: 24 }} lg={{ span: 12, offset: 6 }}>
          <Row className="center">
            <h2 style={{display:"inline"}}>{post.title}</h2>
            <h5 style={{display:"inline"}}>
              <i> - {moment(post.createdAt.toDate()).format("MM/DD/YYYY")}</i>
            </h5>
          </Row>
          <Row>
            <Markdown
              escapeHtml={false}
              source={post.body}
            />
          </Row>
        </Col>
      </Row>
    );
  }
}

export default PostItem;
