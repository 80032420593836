import { ADD_JOURNAL, UPDATE_JOURNAL, DELETE_JOURNAL } from '../constants';

const defaultState = {
    journals: []
};

const blogReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_JOURNAL:
      console.log('Journal added');
      return state;
    case UPDATE_JOURNAL:
      console.log('Journal updated');
      return state;
    case DELETE_JOURNAL:
      console.log('Journal deleted');
      return state;
    default:
      return state;
  }
};

export default blogReducer;
