import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col, notification } from 'antd';
import Reaptcha from 'reaptcha';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Contact extends Component {
  state= {
    verified: false,
  }

  componentDidMount() {
    // To disabled submit button at the beginning.
    // use help props to default no warning.
    this.props.form.validateFields();
  }

  onVerify = recaptchaResponse => {
    this.setState({
      verified: true,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const formdata = new FormData(e.target)
        // convert FormData to json object
        // SOURCE: https://stackoverflow.com/a/46774073
        const json = {}
        formdata.forEach((value, prop) => {
          // console.log(value, prop)
          json[prop] = value
        })
        // console.log(formdata)
        // convert json to urlencoded query string
        // SOURCE: https://stackoverflow.com/a/37562814 (comments)
        const formBody = Object.keys(json).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(json[key])).join('&')
        // console.log(formBody)
        // POST the request to Staticman's API endpoint
        fetch("https://us-central1-journeyinpieces.cloudfunctions.net/contactus", {
          method: "POST",
          headers: {"Content-Type": "application/x-www-form-urlencoded"},
          body: formBody,
        })
        .then(response => {
          response.json()
          .then(result => {
            if (result.success === true) {
              notification["success"]({
                message: "Success!",
                description: 'Thank you for contacting us. We will respond to you shortly.',
                duration: 6
              })
            } else {
              notification["error"]({
                message: "Error!",
                description: 'An error has occured. Please fill out the form again.',
                duration: 6
              })
            }
          })
          .catch(error => {
            console.log(error)
          })
        })
      }}
    )

    this.props.form.resetFields();
    this.captcha.reset()
    this.setState({verified: false})
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const nameError = isFieldTouched('name') && getFieldError('name');
    const emailError = isFieldTouched('email') && getFieldError('email');
    const messageError = isFieldTouched('message') && getFieldError('message');
    return (
      <Row className="border">
        <form className="ant-form" layout="horizontal" onSubmit={this.handleSubmit}>
          <Row gutter={24}>
          <Col span={12}>
          <Form.Item validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
            {getFieldDecorator('name', {
              rules: [
                {type: 'string', message: "not valid"},
                { required: true, message: 'Please input your name.' }],
                })(
                  <Input
                    name="name"
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Your Name"
                  />,
            )}
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Please input your email.' }],
                })(
                  <Input
                    name="email"
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="your@email.com"
                  />,
            )}
          </Form.Item>
          </Col>
          </Row>
          <Row>
          <Col span={24}>
          <Form.Item validateStatus={messageError ? 'error' : ''} help={messageError || ''}>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Please leave us a message.' }],
            })(
              <Input.TextArea
                name="message"
                placeholder="Your message"
              />,
            )}
          </Form.Item>
          </Col>
          </Row>
          <Row className="center">
            <Form.Item>
              <Reaptcha
                ref={e => (this.captcha = e)}
                sitekey="6LcSoJMUAAAAAPz8sparGmZSuqDVV3Pf0PwOhAuA"
                onVerify={this.onVerify}
              />
            </Form.Item>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              block
              htmlType="submit"
              disabled={hasErrors(getFieldsError()) || !this.state.verified}
            >
              Submit
            </Button>
          </Form.Item>
        </form>
      </Row>
    )
  }
}

export default Form.create()(Contact);
