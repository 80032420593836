import React, { Component } from 'react';
import { Row, Icon } from 'antd';
import 'antd/dist/antd.css';
import PostForm from './PostForm';

import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

class PostEdit extends Component {
  state = {
    post: [],
    id: null,
  }

  componentDidMount() {
    const {firestore } = this.props
    // console.log('props', this.props)
    const qid = this.props.match.params.slug
    firestore.collection('journals').doc(qid).get().then(res => {
      // console.log('data', res.data())
      this.setState({post: res.data(), id: qid})
    })
  }

  render() {
    // console.log(this.state)
    const { post, id } = this.state
    return (
      <Row>
      {
        post.length === 0 ?
          <Row className="center"><Icon type="loading" /></Row>
        :
          <PostForm post={post} id={id} />
      }
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore
)(PostEdit);
