import React, { Component } from 'react';
import { Drawer, Button, Icon } from 'antd';
import NavbarView from './NavbarView';


class Navbar extends Component {
  state = {
    visible: false
  };

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClick = () => {
    this.setState({
      visible: false
    })
  }

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    // console.log(this.props, this.state)
    return (
      <div className="menu">
        <div className="center" id="brand">
          <Button
            className="menu__mobile-button"
            type="primary"
            onClick={this.showDrawer}
          >
            <Icon type="menu" style={{color: "black"}}/>
          </Button>
          <a href="/">Journey in Pieces</a>
        </div>
        <div className="menu__container">
          <div className="nav-menu">
          <NavbarView mode="horizontal"
            firebase={this.props.firebase}
          />
          </div>
          <Drawer
            bodyStyle={{background: "#40a9ff"}}
            placement="left"
            className="menu_drawer"
            closable={false}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <NavbarView mode="inline"
              onClick={this.onClick}
              firebase={this.props.firebase}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

export default Navbar;
