import { ADD_JOURNAL, ADD_ERROR, UPDATE_JOURNAL, UPDATE_ERROR, DELETE_JOURNAL, DELETE_ERROR } from '../constants';

// this is when thunk comes in to do async calls.
export const addJournal = (journal, firestore) => {
  return (dispatch, getState) => {
    // console.log('journal', journal, 'state', getState(), 'firestore', firestore)
    const profile = getState().firebase.profile;
    const authorId = getState().firebase.auth.uid;

    firestore.collection('journals').doc(journal.slug).set({
      title: journal.title,
      desc: journal.desc,
      body: journal.body,
      draft: journal.draft,
      slug: journal.slug,
      author: profile.displayName,
      authorId: authorId,
      createdAt: new Date()
    })
    .then(res => {
      // console.log(res)
      dispatch({type: ADD_JOURNAL, journal});
    })
    .catch(err => {
      dispatch({type: ADD_ERROR, err});
    })
  };
};

export const updateJournal = (id, journal, firestore) => {
  // console.log(id, journal, firestore);
  return (dispatch, getState) => {
    firestore.collection('journals').doc(id).update({
      title: journal.title,
      desc: journal.desc,
      body: journal.body,
      draft: journal.draft,
      slug: journal.slug,
    })
    .then(res => {
      // console.log(res)
      dispatch({type: UPDATE_JOURNAL, journal});
    })
    .catch(err => {
      dispatch({type: UPDATE_ERROR, err});
    })
  };
};


export const deleteJournal = (id, firestore) => {
    // console.log("id", id);
    return (dispatch, getState) => {
        firestore.collection('journals').doc(id).delete().then(
            () => {
                dispatch({type: DELETE_JOURNAL});
            })
            .catch(err => {
                dispatch({type: DELETE_ERROR, err});
            })
    }

};
