import React, { Component } from 'react';
import { Row, Button, Input, message, BackTop, Switch } from 'antd';
import 'antd/dist/antd.css';
import { Redirect } from 'react-router-dom';
import { withRouter } from "react-router";
import { Slugify } from '../../Utils.js';
import { addJournal, updateJournal } from '../../actions/blogActions';

import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

//react-draft-wysiwyg
//https://medium.com/@rjmascolo/putting-a-draft-js-wysiwyg-editor-into-your-react-project-8f82493a742
// import css directly
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

class PostForm extends Component {
    state = {
      title: "",
      desc: "",
      body: "",
      error: "",
      existed: false,
      draft: true,
      editorState: EditorState.createEmpty(),
      slug: ""
    }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  updateLink = (data) => {
    // console.log(data)
    const { id } = this.state;

    if (this.state.existed) {
      this.props.updateJournal(id, data, this.props.firestore)
    } else {
      this.props.addJournal(data, this.props.firestore)
    }

    message.success('Post is updated.')
    this.props.history.push(`/journal/edit/${this.state.slug}`)
  }

  handleDraftChange = (e) => {
    // e.preventDefault();
    this.setState({
      draft: !this.state.draft
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const rawContentState = convertToRaw(this.state.editorState.getCurrentContent());
    let html = draftToHtml(rawContentState);

    this.setState(
      {
        body: html,
        slug: Slugify(this.state.title)
      },
      () => this.updateLink(this.state)
    )
  }

  handleChange = (e) => {
      e.preventDefault();
      let key = e.target.name;
      let value = e.target.value;
      // console.log('key', key,'value', value);
      this.setState({
          [key]:value
      });
      // console.log(this.state);
  }

  componentDidMount() {
    const post = this.props.postDetail || this.props.post;
    const id = this.props.id
    // console.log('CDM from PostForm', post);

    if ( post !== undefined) {
      // console.log('this.updateLink(data)');
      // console.log('link in componentDidMount', link.description);
      // console.log('descInDraft', descInDraft);

      // Not working for responsive images!!
      // setting up default content for react-draft-wysiwyg
      const descInDraft = htmlToDraft(post.body);
      const { contentBlocks, entityMap } = descInDraft;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);

      // Version that removes formatting.
      // https://draftjs.org/docs/api-reference-data-conversion#convertfromhtml
      // const descInDraft = convertFromHTML(link.description);
      // const state = ContentState.createFromBlockArray(
      //   descInDraft.contentBlocks,
      //   descInDraft.entityMap
      // );

      this.setState({
        title : post.title,
        desc: post.desc,
        body: post.body,
        existed: true,
        draft: post.draft,
        editorState,
        id
      });
    }
  }

  render() {
    // console.log(this.props)
    const { editorState } = this.state;
    // const { redirec } = this.state;
    // if (redirect) {
    //   return <Redirect to="/post" />;
    // }

    const { auth } = this.props;
    const { isLoaded, isEmpty } = auth

    if (isLoaded && isEmpty) {
      return <Redirect to='/signin' />
    }

    return (
      <Row className="border">
        <form className="blog-form" onSubmit={this.handleSubmit}>
          <Row gutter={24} className="ant-form-item">
            <Input name="title" placeholder="Title" type="text" value={this.state.title} onChange={this.handleChange} />
          </Row>
          <Row gutter={24} className="ant-form-item">
            <Input name="desc" placeholder="Description" type="text" value={this.state.desc} onChange={this.handleChange} />
          </Row>
          <Row gutter={24} className="ant-form-item">
            <Switch id="draft" checked={this.state.draft} onChange={this.handleDraftChange} checkedChildren="Draft" />
          </Row>
          <Row gutter={24} className="ant-form-item" id="editor">
            <Editor
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                image: {
                  urlEnabled: true,
                  // uploadEnabled: true,
                  // // uploadCallback: this.uploadCallback,
                  alignmentEnabled: true,
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: '100%',
                    width: '100%',
                  },
                },
              }}
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.onEditorStateChange}
            />
          </Row>
          <br />
          <Row className="ant-form-item center">
            <Button htmlType="submit">Save</Button>
          </Row>
        </form>
        <br />
        <BackTop />
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    firebase: state.firebase,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addJournal: (journal, firebase) => dispatch(addJournal(journal, firebase)),
    updateJournal: (id, journal, firebase) => dispatch(updateJournal(id, journal, firebase))
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withFirestore
)(PostForm);
