import React, { Component } from 'react';
import { Row, Col, Icon, Pagination, Divider } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { connect } from 'react-redux';
import { withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';

class PostList extends Component {
  state = {
    journals: [],
    displayedJournals:[],
    displayLimit: 10,
    pages: null,
  }

  pagination = (page) => {
    // slice list in state and update display
    const { journals, displayLimit } = this.state
    if (page === 1) {
      this.setState({displayedJournals: journals.slice(0, displayLimit)})
    }

    this.setState({displayedJournals: journals.slice((page-1)*displayLimit, page*displayLimit)})
  }

  componentDidMount() {
    // get from firestore and store in state
    const { firestore } = this.props
    firestore.collection('journals').orderBy('createdAt', 'desc')
    .get().then(q => {
      this.setState({
        journals: q.docs,
        displayedJournals: q.docs.slice(0, this.state.displayLimit),
        pages: Math.ceil(q.docs.length/this.state.displayLimit),
      })
    })
  }

  render() {
    const { journals, displayedJournals, displayLimit, pages } = this.state;
    const { uid, isLoaded } = this.props.auth
    return (
      <Row className="border">
        <Row type="flex" justify="space-around">
          <Col sm={24} lg={11} xl={11}>
          { isLoaded && uid ?
            <Row className="center">
              <Link to={{
                pathname:`/journal/create`,
              }}
              >
                <span className="black-text" style={{fontSize: 12, padding: 7}}>NEW JOURNAL</span>
              </Link>
              <Divider type="vertical" />
              <Link to={{
                pathname:`/journal/draft`,
              }}
              >
                <span className="black-text" style={{fontSize: 12, padding: 7}}>DRAFTS</span>
              </Link>
              <Divider type="vertical" />
              <Link to={{
                pathname:`/journals/search`,
              }}
              >
                <span className="black-text" style={{fontSize: 12, padding: 7}}>SEARCH</span>
              </Link>
            </Row>
          :
            ""
          }
          <Row>
          {
            displayedJournals === undefined ?
              <Row><Icon type="loading" /></Row>
            :
            displayedJournals.map(q => {
              if (!q.data().draft) {
                const createdAt = moment(q.data().createdAt.toDate())
                return (
                  <Col key={q.id}>
                    <Row>
                      <h2 style={{display:"inline"}}><Link to={{
                        pathname:`/journal/${q.id}`,
                        // state: {id: q.id, slug: q.data().slug}
                      }}
                      >
                          {q.data().title}
                      </Link></h2>
                      <h5 style={{display:"inline"}}>
                        <i> - {moment(createdAt).format("MM/DD/YYYY")}</i>
                      </h5>
                    </Row>
                    <Row>
                      {q.data().desc}
                    </Row>
                  </Col>
                )
              } else {
                return ""
              }
            })
          }
          </Row>
          {
            pages === null ?
              <Row className="center"><Icon type="loading" /></Row>
            :
            <Row>
              <Pagination
                total={journals.length}
                hideOnSinglePage={false}
                defaultCurrent={1}
                size={pages}
                pageSize={displayLimit}
                onChange={this.pagination}
              />
            </Row>
          }
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    journals: state.firestore.ordered.journals,
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore,
)(PostList);
