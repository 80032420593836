import { combineReducers } from 'redux'
// firebase and firestore reducers
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import authReducer from './authReducer'
import blogReducer from './blogReducer'

const rootReducer = combineReducers({
  blog: blogReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  authState: authReducer
})

export default rootReducer;
