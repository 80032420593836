// add slugify title
// https://gist.github.com/mathewbyrne/1280286

import React, { Component } from 'react';
import { Row, Upload, Icon, Button, message } from 'antd';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';

class AddPhoto extends Component {
  state = {
    fileList: [],
  }

  handleFileChange = info => {
    // console.log(info)
    let fileList = [...info.fileList];
    this.setState({ fileList });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state)
    const { firebase, firestore } = this.props;
    const { id } = this.props.match.params;
    firestore.collection('albums').doc(id).get().then(data => {
      // getting dirpath to gs storage.
      const pathName = data.data().slug;
      const { fileList } = this.state;

      // for each file, change name and put it in the storage folder
      if (fileList.length > 0) {
        for (var i = 0; i < fileList.length; i++) {
          // console.log(fileList[i]);
          var today = new Date()
          var y = today.getFullYear()
          var m = today.getMonth() + 1
          var d = today.getDate()
          firebase.storage().ref().child(
            `${pathName}/${fileList[i].name}_${y}_${m}_${d}`
          ).put(fileList[i].originFileObj).then(res => {
            // console.log(res)
            res.ref.getDownloadURL().then(url => {
              firestore.collection('albums').doc(id).collection('photos').add({url: url, updatedAt: new Date()})
            })
          }).then(()=>{
            message.success('Photos are uploaded.')
          })
        }
      }
    }).then(() =>{
      message.success('Photos are currently uploading.')
    })
  }

  componentDidMount() {
    const { id } = this.props.location.state
    this.setState({id})
  }

  render() {
    // console.log(this.state)

    const { auth } = this.props;
    const { isLoaded, isEmpty } = auth

    const { fileList } = this.state;
    const props = {
      onChange: this.handleFileChange,
      onRemove: file => {
        // console.log(file)
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
      multiple: true,
    };

    if (isLoaded && isEmpty) {
      return <Redirect to='/signin' />
    }

    return (
      <Row className="center border">
        <form className="ant-form" onSubmit={this.handleSubmit} >
          <Row className="ant-form-item">
            <Upload {...props}>
              <Button block>
                <Icon type="upload" />Select Photos
              </Button>
            </Upload>
          </Row>
          <Row className="ant-form-item">
            <Button htmlType="submit" block>Upload Photos</Button>
          </Row>
        </form>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore,
)(AddPhoto);
