import React, { Component } from 'react';
import { Row, Col, Icon, message, BackTop, Divider } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
import PostItem from './PostItem';
import { deleteJournal } from '../../actions/blogActions';

import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';


class PostDetail extends Component {
  // add componentDidMount just incase the link is directly accessed.
  state = {
    id: '',
    post: []
  }

  handleDelete = (e) => {
        e.preventDefault();
        // console.log(this.props.todo.id);
        const res = window.confirm(`Are you sure you want to delete this journal? You can't undo this action.`);
        if (res === true) {
          this.props.deleteJournal(this.state.id, this.props.firestore)
          message.success('Post is now deleted.')
          this.props.history.push('/')
        }

    }

  componentDidMount() {
    const { firestore } = this.props
    // console.log('props', this.props)
    const qid = this.props.match.params.slug
    firestore.collection('journals').doc(qid).get().then(res => {
      // console.log('data', res.data())
      this.setState({post: res.data(), id: qid})
    })

    // below is using ID to do. I'm switching to use document ID as the main data point
    // if (this.props.location.state === undefined) {
    //   const qid = values.id
    //   this.setState({id: qid})
    //   firestore.collection('journals').doc().get().then(res => {
    //     this.setState({post: res.data(), id: qid})
    //   })
    // } else if (this.props.match.params.slug) {
    //   const qid = this.props.match.params.slug
    //   firestore.collection('journals').doc(qid).get().then(res => {
    //     this.setState({post: res.data(), id: qid})
    //   })
    // } else {
    //   const qid = this.props.location.state.id
    //   firestore.collection('journals').doc(qid).get().then(res => {
    //     this.setState({post: res.data(), id: qid})
    //   })
    // }
  }

  render() {
    const { post, id } = this.state;
    const { auth } = this.props;
    const { isLoaded, isEmpty } = auth
    // console.log(isLoaded)

    return (
      <Row className='border'>
        {
          isLoaded && !isEmpty ?
          (
            post.authorId !== this.props.auth.uid
          ?
            ""
          :
            <Row className="center">
              <Col>
                <Link to={{
                  pathname:`/journal/edit/${post.slug}`,
                  state:{post: post, id: id}}}>
                  <span className="black-text" style={{fontSize: 12, padding: 7}}>EDIT JOURNAL</span>
                </Link>
                <Divider type="vertical" />
                <Link to="/" onClick={this.handleDelete}>
                  <span className="black-text"  style={{fontSize: 12}}>DELETE JOURNAL</span>
                </Link>
              </Col>
            </Row>
          )
          :
          ""
        }

        {
          post.length === 0 ?
            <Row className="row"><Icon type="loading" /></Row>
          :
            <PostItem post={post} />
        }
        <BackTop />
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    deleteJournal: (id, firestore) => dispatch(deleteJournal(id, firestore))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  withFirestore
)(PostDetail);
