import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Nav from './components/navs/Nav';
import Contact from './components/navs/Contact';
import Footer from './components/navs/Footer';
import Main from './components/navs/Main';
import SignIn from './components/auth/SignIn';
import ForgotPw from './components/auth/ForgotPw';

// gallery
import AlbumList from './components/gallery/AlbumList';
import AlbumView from './components/gallery/AlbumView';
import AddAlbum from './components/gallery/AddAlbum';
import AddPhoto from './components/gallery/AddPhoto';
// blog
import PostList from './components/blog/PostList';
import PostDetail from './components/blog/PostDetail';
import PostForm from './components/blog/PostForm';
import PostEdit from './components/blog/PostEdit';
import DraftPosts from './components/blog/DraftPosts';
import Search from './components/blog/Search';

class App extends Component {
  render() {
    // console.log(this.props)
    const { firebase } = this.props
    return (
      <BrowserRouter>
        <div className="App">
          <Nav firebase={firebase} />
          <Switch>
            <Route exact path='/' component={Main} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/password_rest" component={ForgotPw} />
            {/* gallery routes */}
            <Route exact path="/gallery/add" component={AddAlbum} />
            <Route path="/photo/add/:id" component={AddPhoto} />
            <Route path="/gallery/:slug" component={AlbumView}/>
            <Route path="/galleries" component={AlbumList}/>
            {/* blog routes */}
            <Route exact path="/journals" component={PostList} />
            <Route exact path="/journals/search" component={Search} />
            <Route exact path="/journal/draft" component={DraftPosts} />
            <Route exact path="/journal/create" component={PostForm} />
            <Route exact path="/journal/:slug" component={PostDetail} />
            <Route exact path="/journal/edit/:slug" component={PostEdit} />

          </Switch>
          <Footer />
        </div>

      </BrowserRouter>
    );
  }
}

export default App;
