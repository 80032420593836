import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

var config

const hostname = window && window.location && window.location.hostname;

if (process.env.NODE_ENV === "development" || hostname === "localhost" || hostname === "journeyinpieces-dev.web.app") {
  config = {
    apiKey: "AIzaSyBIyXXpo9dSymPZ_yeaVzmunAEonVK8EP8",
    authDomain: "journeyinpieces-dev.firebaseapp.com",
    databaseURL: "https://journeyinpieces-dev.firebaseio.com",
    projectId: "journeyinpieces-dev",
    storageBucket: "journeyinpieces-dev.appspot.com",
    messagingSenderId: "43465076102",
    appId: "1:43465076102:web:4fa9c03bbdcd445f315007",
    measurementId: "G-HLW388HF6E"
  };
} else {
  config = {
    apiKey: "AIzaSyAsSv2u_H94OKqabfqft9e1Qfh5zgHtoW8",
    authDomain: "journeyinpieces.firebaseapp.com",
    databaseURL: "https://journeyinpieces.firebaseio.com",
    projectId: "journeyinpieces",
    storageBucket: "gs://journeyinpieces.appspot.com",
    messagingSenderId: "267138740393"
  };
}


firebase.initializeApp(config);
firebase.firestore()
firebase.storage()

export default firebase;
