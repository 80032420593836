import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, BackTop, Divider } from 'antd';
import 'antd/dist/antd.css';

import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';

class AlbumView extends Component {
  // get the id from params or pass it down from AlbumList.
  state = {
    photos: [],
    displayLimit: 6,
    isLoading: false,
    sortBy: 'desc',
    displaySort: 'NEW'
  }

  initialFetch = (id) => {
    const { firestore } = this.props
    const { sortBy } = this.state
    // setting a query
    const first = firestore.collection('albums').doc(id).collection('photos')
    .limit(this.state.displayLimit)

    // getting first set of data
    first.orderBy('updatedAt', sortBy).get().then(docs => {
      const lastVisible = docs.docs[docs.docs.length-1];
      // console.log('last', lastVisible)
      this.setState({photos: docs.docs, id, lastVisible})
    })
  }

  loadMore = () => {
    // exit if at end of data
    if (this.state.lastVisible === undefined) {
      return;
    }

    this.setState({isLoading: true})
    const { firestore } = this.props
    const { sortBy } = this.state
    const next = firestore.collection('albums').doc(this.state.id)
                .collection('photos').orderBy('updatedAt', sortBy)
                .startAfter(this.state.lastVisible)
                .limit(this.state.displayLimit)

    next.get().then(docs => {
      const lastVisible = docs.docs[docs.docs.length-1];
      this.setState({
        photos: [...this.state.photos, ...docs.docs],
        lastVisible,
        isLoading: false
      })
    })
  }

  handleSort = () => {
    const sortBy = (this.state.sortBy === 'asc') ? 'desc' : 'asc'
    const displaySort = (this.state.displaySort === 'OLD') ? 'NEW' : 'OLD'
    this.setState(
      {sortBy, photos: [], displaySort},
      () => {
        const qid = this.props.match.params.slug
        // infinite scroll to load more data
        window.addEventListener('scroll', this.handleScroll, false);
        this.initialFetch(qid)
      }
    )
  }

  // infinite scroll to load more data
  handleScroll = () => {
    if (
      (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)
      && !this.state.isLoading
    ) {
      this.loadMore();
    }
  }

  componentDidMount() {
    // console.log(this.props)
    const qid = this.props.match.params.slug
    // infinite scroll to load more data
    window.addEventListener('scroll', this.handleScroll, false);
    this.initialFetch(qid)
  }

  componentWillUnmount() {
    // console.log('unmounted')
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  render() {
    const { photos, id, displaySort } = this.state;
    const { uid, isLoaded } = this.props.auth;
    return(
      <Row className="center border">
        <Col span={24}>
          {
            isLoaded && uid ?
              <Row className="center">
                  <Link to={{
                    // can use id or title
                    // or slugify the title
                    pathname:`/photo/add/${id}`,
                    state: {id: id}
                  }}
                  >
                    <span className="black-text" style={{fontSize: 12, padding: 7}}>ADD PHOTOS</span>
                  </Link>
                  <Divider type="vertical" />
                <span className="black-text" style={{fontSize: 9}} onClick={this.handleSort}>SORTED BY: {displaySort}</span>
              </Row>
            :
              <Row>
                <span className="black-text" style={{fontSize: 9}} onClick={this.handleSort}>SORTED BY: {displaySort}</span>
              </Row>
          }
        </Col>
        <Col span={24}>
          <Row>
          {
            photos && photos.map(photo => {
              // console.log(photo)
              return (
                <Col key={photo.id} className="polaroid">
                  <img src={photo.data().url} alt={photo.id} className="responsive-img"/>
                </Col>
              )
            })
          }
          </Row>
        </Col>
        <BackTop />
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore
)(AlbumView);
