import React, { Component } from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd'
import { connect } from 'react-redux';
import { signIn } from '../../actions/authActions';
import { compose } from 'redux';
import { withFirebase } from 'react-redux-firebase';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class SignIn extends Component {

  handleSubmit = e => {
    e.preventDefault();
    const { firebase } = this.props
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.props.signIn({...values}, firebase)
      }
    });
  };

  componentDidMount() {
    // To disabled submit button at the beginning.
    // use help props to default no warning.
    this.props.form.validateFields();
  }

  render() {
    // console.log(this.props)
    const { authState } = this.props
    // Redirect to home if user is logged in.
    const { isLoaded, isEmpty } = this.props.auth
    if (isLoaded && !isEmpty) {
      return <Redirect to='/' />
    }

    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const emailError = isFieldTouched('email') && getFieldError('email');
    const passwordError = isFieldTouched('password') && getFieldError('password');

    return (
      <Row className="border">
        <Form layout="horizontal" onSubmit={this.handleSubmit}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item validateStatus={emailError ? 'error' : ''} help={emailError || ''}>
                {getFieldDecorator('email', {
                  rules: [{ required: true, message: 'Please input your email.' }],
                    })(
                      <Input
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="your@email.com"
                      />,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input your password!',
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                })(<Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Password" />)}
              </Form.Item>
            </Col>
          </Row>
          {
            authState.authError ?
              <Row className="center">
                <span style={{color: "red"}}>{authState.authError}</span>
              </Row>
            :
              ""
          }
          <Row className="center">
            <Form.Item>
              <Button type="primary" block htmlType="submit" disabled={hasErrors(getFieldsError())}>
                Sign In
              </Button>
              <Link to="/password_rest">Forgot password?</Link>
            </Form.Item>
          </Row>
        </Form>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    authState: state.authState,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    signIn: (cred, firebase) => {
      dispatch(signIn(cred, firebase))
    },
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
  Form.create()
)(SignIn)
