import { SIGN_IN, SIGN_OUT } from '../constants';

export const signIn = (cred, firebase) => {
  return (dispatch, getState) => {
    // console.log('cred', cred, 'firebase', firebase);
    firebase.login(cred)
    .then(res => {
      // console.log('user ', res.user.user)
      dispatch({ type: SIGN_IN, res})
    })
    .catch(err => {
      dispatch({ type: 'LOGIN_ERROR', err})
    })
  }
}

export const signOut = (firebase) => {
  return (dispatch, getState) => {
    firebase.auth().signOut().then(() => {
      console.log('logged out');
      dispatch({type: SIGN_OUT})
    })
  }
}
