// Auth
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGNIN_ERROR = "SIGNIN_ERROR";

// blog
export const ADD_JOURNAL = "ADD_JOURNAL";
export const ADD_ERROR = "ADD_ERROR";
export const UPDATE_JOURNAL = "UPDATE_JOURNAL";
export const UPDATE_ERROR = "UPDATE_ERROR";
export const DELETE_JOURNAL = "DELETE_JOURNAL";
export const DELETE_ERROR = "DELETE_ERROR";
