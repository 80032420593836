import React, { Component } from 'react';
import { Row, Col, Icon, Divider } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';

import { connect } from 'react-redux';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { compose } from 'redux';

class DraftPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      error: null,
    }
  }

  componentDidMount() {
    // get from firestore
    const { firestore } = this.props
    firestore.collection('journals').get().then(q => {
      this.setState({posts: q.docs})
    })
  }

  render() {
    const {posts} = this.state;
    const { uid, isLoaded } = this.props.auth
    // console.log(this.state);
    return (
      <Row type="flex" className="border" justify="space-around">
        <Col sm={24} lg={11} xl={11}>
          {
            isLoaded && uid ?
            <Row className="center">
              <Link to={{
                pathname:`/journal/create`,
                }}
              >
                <span className="black-text" style={{fontSize: 12, padding: 7}}>NEW JOURNAL</span>
              </Link>
              <Divider type="vertical" />
              <Link to={{
                pathname:`/journal/draft`,
              }}
              >
                <span className="black-text" style={{fontSize: 12, padding: 7}}>DRAFTS</span>
              </Link>
            </Row>
          :
            ""
          }
          <Row>
            {
              posts.length === 0 ?
                <Row className="center"><Icon type="loading" /></Row>
              :
              posts.map(q => {
                if (q.data().draft) {
                  return (
                    <Col key={q.id}>
                      <Row>
                        <h2><Link to={{
                          pathname:`/journal/${q.data().slug}`,
                          state: {id: q.id, slug: q.data().slug}
                        }}
                        >
                            {q.data().title}
                        </Link></h2>
                      </Row>
                      <Row>
                        <Markdown
                          escapeHtml={false}
                          source={q.data().desc}
                        />
                      </Row>
                    </Col>
                  )
                } else {
                  return ""
                }
              })
            }
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore,
)(DraftPosts);
