import React, { Component } from 'react';
import { Row, Col, Icon } from 'antd';
import 'antd/dist/antd.css';
import { Link } from 'react-router-dom';
import moment from 'moment';

// connect to redux
import { connect } from 'react-redux';

//connect to firestore
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';

class Main extends Component {

  render() {
    // console.log(this.props);
    const {journals} = this.props;

    return (
      <Row className="border">
        <Row type="flex" justify="space-around">
          <Col sm={22} lg={22} xl={11}>
          {
            journals === undefined ?
              <Row className="center"><Icon type="loading" /></Row>
            :
            journals.map(q => {
              // console.log(q)
              if (!q.draft) {
                const createdAt = moment(q.createdAt.toDate())
                return (
                  <Row key={q.id}>
                    <Row>
                      <Link to={{
                        pathname:`/journal/${q.id}`,
                        // state: {id: q.id, slug: q.slug}
                      }}
                      >
                          <h2 style={{display:"inline"}}>{q.title}</h2>
                      </Link>
                      <h5 style={{display:"inline"}}>
                        <i> - {moment(createdAt).format("MM/DD/YYYY")}</i>
                      </h5>
                    </Row>
                    <Row>
                      {q.desc}
                    </Row>
                  </Row>
                )
              } else {
                return ""
              }
            })
          }
          </Col>
          {/*
            <Col lg={20} xl={10}>
              test
            </Col>
          */}
        </Row>
      </Row>
    );
  }
}


const mapStateToProps = state => {
  // console.log('state', state)
  return {
    // state is from rootReducer, "journals" is the name assigned to each reducer.
    journals: state.firestore.ordered.journals,
    auth: state.firebase.auth
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect([
    // order by created time in descending order.
    // can add limit to limit the displayed records.
    { collection: 'journals', orderBy: ['createdAt', 'desc'], limit: 5 }
  ])
)(Main);
