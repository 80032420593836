// add slugify title
// https://gist.github.com/mathewbyrne/1280286

import React, { Component } from 'react';
import { Row, Input, Upload, Icon, Button, message } from 'antd';
import 'antd/dist/antd.css';
import { withFirebase, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Slugify } from '../../Utils.js';
import { Redirect } from 'react-router-dom';

class AddAlbum extends Component {
  state = {
    title: '',
    fileList: [],
    slug: '',
  }

  handleChange = (e) => {
    this.setState({
      title: e.target.value,
      slug: Slugify(e.target.value)
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // console.log(this.state)
    const {firebase, firestore } = this.props;

    // Need to update to rename file

    // For a single file. Update handleFileChange
    firebase.uploadFile(this.state.slug, this.state.fileList[0].originFileObj)
    .then(res=>{
      // getting downloadURL and then add to firestore
      res.uploadTaskSnapshot.ref.getDownloadURL().then(url => {
        // adding the url to firestore where albumList is populated
        firestore.collection('albums').doc(this.state.slug).set({
            coverURL: url,
            title: this.state.title,
            slug: this.state.slug,
            updatedAt: new Date(),
        })
        .then(res => {
          // console.log(res)
          message.success('Album is now created.')
        })
        .catch(err => {
          console.log(err)
        })
      })
    })
  }

  handleFileChange = info => {
    // console.log(info)
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);
    this.setState({ fileList });
  };

  render() {
    // console.log(this.state)
    const { auth } = this.props;
    const { isLoaded, isEmpty } = auth

    const { fileList } = this.state;
    const props = {
      onChange: this.handleFileChange,
      onRemove: file => {
        // console.log(file)
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
      multiple: false,
    };

    if (isLoaded && isEmpty) {
      return <Redirect to='/signin' />
    }

    return (
      <Row className="center border">
        <form className="ant-form" onSubmit={this.handleSubmit}>
          <Row className="ant-form-item">
            <Input placeholder="Gallery title" type="text" name="title" onChange={this.handleChange} required />
          </Row>
          <Row className="ant-form-item">
            <Upload {...props}>
              <Button block>
                <Icon type="upload" />Select File
              </Button>
            </Upload>
          </Row>
          <Row className="ant-form-item">
            <Button htmlType="submit" block>Create Album</Button>
          </Row>
        </form>
      </Row>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  withFirebase,
  withFirestore,
)(AddAlbum);
