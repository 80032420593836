import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon, Row } from 'antd';
import { compose } from 'redux';
import { signOut } from '../../actions/authActions';
import { withFirebase } from 'react-redux-firebase';

const Footer = (props) => {
  // console.log(props);
  const { uid, isLoaded } = props.auth
  return (
    <Row style={{marginLeft: "5px"}}>
      { isLoaded && uid ?
        <Link to="/" onClick={(firebase) => {props.signOut(props.firebase)}}>
          <span className="nav-text" style={{fontSize: 8}}>SIGN OUT</span>
        </Link>
      :
        <div>
        { isLoaded ?
          <a href="/signin"><span className="nav-text" style={{fontSize: 8}}>SIGN IN</span></a>
        :
          <Icon type="loading" />
        }
        </div>
      }
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: (firebase) => dispatch(signOut(firebase))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFirebase,
)(Footer);
