import React from 'react';
import { Row, Menu } from 'antd';
import { Link } from 'react-router-dom'

import { connect } from 'react-redux';
import { signOut } from '../../actions/authActions';

const NavbarView = (props) => {
    // console.log('props', props)
  return (
    <Row>
      <Menu className="center menu" mode={props.mode}>
        <Menu.Item className="menu" key="galleries">
          <Link
            to="/galleries"
            className="nav-text"
            onClick={props.onClick}
          >
            OUR GALLERIES
          </Link>
        </Menu.Item>
        <Menu.Item className="menu" key="journals">
          <Link
            to='/journals'
            className="nav-text"
            onClick={props.onClick}
          >
            OUR JOURNALS
          </Link>
        </Menu.Item>
        <Menu.Item className="menu" key="about">
          <Link
            to='#'
            className="nav-text"
            onClick={props.onClick}
          >
            ABOUT US
          </Link>
        </Menu.Item>
        <Menu.Item className="menu" key="contact">
           <Link
            to="/contact"
            className="nav-text"
            onClick={props.onClick}
           >
             CONTACT US
           </Link>
         </Menu.Item>
      </Menu>
    </Row>
  )
}

const mapStateToProps = state => {
  // console.log('state', state)
  return {
    auth: state.firebase.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOut: (firebase) => dispatch(signOut(firebase))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavbarView);
