import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Icon, BackTop, Divider } from 'antd';
import 'antd/dist/antd.css';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFirestore } from 'react-redux-firebase';

class AlbumList extends Component {
  state = {
    qs:[],
    displayLimit: 5,
    isLoading: false,
    sortBy: 'desc',
    displaySort: 'NEW'
  }

  initialFetch = () => {
    const { firestore } = this.props
    const { sortBy } = this.state
    // setting a query
    const first = firestore.collection('albums').limit(this.state.displayLimit)

    // getting first set of data
    first.orderBy('updatedAt', sortBy).get().then(docs => {
      const lastVisible = docs.docs[docs.docs.length-1];
      // console.log('last', lastVisible)
      this.setState({qs: docs.docs, lastVisible})
    })
  }

  loadMore = () => {
    // exit if at end of data
    if (this.state.lastVisible === undefined) {
      return;
    }

    this.setState({isLoading: true})
    const { firestore } = this.props
    const { sortBy } = this.state
    const next = firestore.collection('albums').orderBy('updatedAt', sortBy)
                .startAfter(this.state.lastVisible)
                .limit(this.state.displayLimit)

    next.get().then(docs => {
      const lastVisible = docs.docs[docs.docs.length-1];
      this.setState({
        qs: [...this.state.qs, ...docs.docs],
        lastVisible,
        isLoading: false
      })
    })
  }

  handleSort = () => {
    const sortBy = (this.state.sortBy === 'asc') ? 'desc' : 'asc'
    const displaySort = (this.state.displaySort === 'OLD') ? 'NEW' : 'OLD'
    this.setState(
      {sortBy, photos: [], displaySort},
      () => {
        const qid = this.props.match.params.slug
        // infinite scroll to load more data
        window.addEventListener('scroll', this.handleScroll, false);
        this.initialFetch(qid)
      }
    )
  }

  // infinite scroll to load more data
  handleScroll = () => {
    if (
      (window.innerHeight + window.scrollY) >= (document.body.scrollHeight - 500)
      && !this.state.isLoading
    ) {
      this.loadMore();
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, false);
    this.initialFetch()
  }

  componentWillUnmount() {
    // console.log('unmounted')
    window.removeEventListener('scroll', this.handleScroll, false);
  }

  render() {
    const { qs, displaySort }  = this.state;
    const { uid, isLoaded } = this.props.auth

    return (
      <Row className="center border">
        <Col span={24}>
          { isLoaded && uid ?
            <Row className="center">
              <Link to={{
                pathname:`/gallery/add`,
              }}
              >
                <span className="black-text" style={{fontSize: 12}}>NEW GALLERY</span>
              </Link>
              <Divider type="vertical" />
              <span className="black-text" style={{fontSize: 12}} onClick={this.handleSort}>SORTED BY: {displaySort}</span>
            </Row>
          :
            <Row className="center">
              <span className="black-text" style={{fontSize: 12}} onClick={this.handleSort}>SORTED BY: {displaySort}</span>
            </Row>
          }
        </Col>
        <Col span={24}>
          <Row className="center">
            {
              qs.length === 0 ?
              <Row><Icon type="loading" /></Row>
              :
              qs.map(q => {
                // console.log(q.id, q.data().slug)
                return (
                  <Col key={q.id}>
                    <Row className="polaroid">
                      <Link to={{
                        // can use id or title
                        // or slugify the title
                        pathname:`/gallery/${q.id}`,
                      }}
                      >
                          {q.data().title}
                      </Link>
                      <img src={q.data().coverURL} alt={q.data().title} className="responsive-img"/>
                    </Row>
                  </Col>
                )
              })
            }
          </Row>
        </Col>
        <BackTop />
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    firebase: state.firebase
  }
}

export default compose(
  connect(mapStateToProps),
  withFirestore
)(AlbumList);
