import React, { Component } from 'react';
import '../../index.css';

import { connect } from 'react-redux';
import { compose } from 'redux';

class Search extends Component {
  state = {
    journals: [],
    query: "",

  }

  handleChange = (e) => {
      e.preventDefault();
      let key = e.target.name;
      let value = e.target.value;
      // console.log('key', key,'value', value);
      this.setState({
          [key]:value
      });
      // console.log(this.state);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state)
    // this.setState(
    //   {
    //     body: html,
    //     slug: Slugify(this.state.title)
    //   },
    //   () => this.updateLink(this.state)
    // )
  }

  render() {
    console.log(this.props);
    return (
      <div className="row border">
        <form className="col s12" onSubmit={this.handleSubmit}>
          <div className="input-field col s12">
            <i className="material-icons prefix">search</i>
            <label htmlFor="query">What are you searching for?</label>
            <input name="query" type="text" value={this.state.query} onChange={this.handleChange} />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log('state', state)
  return {
    auth: state.firebase.auth,
  }
}

export default compose(
  connect(mapStateToProps),
)(Search);
